<template>
  <!-- BEGIN: Content-->
  <div class="app-content content">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top profile__avatar">
            <div class="col-12">
              <h2 class="content-header-title mb-0">
                {{ title }}
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div class="content-body content-body--senior-call">
        <div class="row">
          <div class="col-12">
            <div
              v-if="loggedUser.roles.includes('super-admin') && lastUpdate && (lastUpdate.successfully_document + lastUpdate.incorrectly_document) > 0"
              class="card"
            >
              <div class="card-header">
                <h4 class="card-title">
                  Last update
                </h4>
                <div class="d-flex align-items-center">
                  <p
                    v-if="lastUpdate"
                    class="card-text font-small-2 me-25 mb-0"
                  >
                    Updated {{ lastUpdate.day }} at {{ lastUpdate.hour }}h
                  </p>
                </div>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-xl-3 col-sm-6 col-12 mb-2 mb-xl-0">
                    <div class="d-flex flex-row">
                      <div class="my-auto">
                        <h2
                          v-if="lastUpdate"
                          class="fw-bolder mb-0"
                        >
                          {{ (lastUpdate.successfully_document + lastUpdate.incorrectly_document) }}
                        </h2>
                        <p class="card-text font-small-3 mb-0">
                          Uploaded documents
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-3 col-sm-6 col-12 mb-2 mb-xl-0">
                    <div class="d-flex flex-row">
                      <div class="my-auto">
                        <h2
                          v-if="lastUpdate"
                          class="fw-bolder mb-0 text-success"
                        >
                          {{ lastUpdate.successfully_document }}
                        </h2>
                        <p class="card-text font-small-3 mb-0">
                          Uploaded successfully
                        </p>
                      </div>
                    </div>
                    <p class="mt-1">
                      <a href="#">View</a>
                    </p>
                  </div>
                  <div class="col-xl-3 col-sm-6 col-12 mb-2 mb-sm-0">
                    <div class="d-flex flex-row">
                      <div class="my-auto">
                        <h2
                          v-if="lastUpdate"
                          class="fw-bolder mb-0 text-warning"
                        >
                          {{ lastUpdate.incorrectly_document }}
                        </h2>
                        <p class="card-text font-small-3 mb-0">
                          uploaded incorrectly
                        </p>
                      </div>
                    </div>
                    <p class="mt-1">
                      <a href="#">View</a>
                    </p>
                  </div>
                  <div class="col-xl-3 col-sm-6 col-12">
                    <div class="d-flex flex-row">
                      <div class="my-auto">
                        <h2
                          v-if="lastUpdate"
                          class="fw-bolder mb-0 text-danger"
                        >
                          {{ lastUpdate.without_document }}
                        </h2>
                        <p class="card-text font-small-3 mb-0">
                          Without document
                        </p>
                      </div>
                    </div>
                    <p class="mt-1">
                      <a href="#">View</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <CompleteTable
              :sorted-field="sortedField"
              :data="data"
              :sort-mapping="sortMapping"
              :title="'payrolls'"
              :total="dataTotal"
              :fetch-path="'payrolls/fetch'"
              :filter-path="'payrolls/filter'"
              :actions="true"
              @selectedItems="saveSelectedItems"
            >
              <template v-slot="{field}">
                <td
                  class="text-end"
                >
                  <b-dropdown
                    variant="link"
                    no-caret
                    toggle-class="p-0"
                    right
                  >
                    <template #button-content>
                      <i data-feather="more-vertical" />
                    </template>
                    <div
                      class="btn-group dropup dropdown-icon-wrapper d-none d-sm-block"
                    >
                      <b-dropdown-item @click="$router.push({name: 'researcher.dashboard', params: { id: field.user.id }})">
                        <i
                          class="me-50"
                          data-feather="user"
                        /> View researcher profile
                      </b-dropdown-item>
                      <div class="dropdown-divider" />
                      <b-dropdown-item @click="downloadFile(field)">
                        <i
                          class="me-50"
                          data-feather="download"
                        /> Download
                      </b-dropdown-item>
                      <b-dropdown-item @click="showDeleteModal(field.id)">
                        <i
                          class="me-50"
                          data-feather="trash-2"
                        /> Delete <span class="float-end"><i data-feather="lock" /></span>
                      </b-dropdown-item>
                    </div>
                  </b-dropdown>
                  <a
                    href=""
                    class="btn btn-icon btn-light-secondary d-block d-sm-none"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasMenu"
                    aria-controls="offcanvasBottom"
                  ><i
                    data-feather="more-vertical"
                  /></a>
                </td>
              </template>
              <template #customfilters>
                <div
                  v-if="loggedUser.roles.includes('super-admin')"
                  class="mb-1"
                >
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >Researcher</label>
                  <v-select
                    label="name"
                    :options="users"
                    :get-option-key="option => option.id"
                    @search="onSearch({ name: $event }, 'users/fetchFakeUsers')"
                    @input="saveFilter($event, 'researcher')"
                  />
                  <hr>
                </div>
                <div
                  v-if="loggedUser.roles.includes('super-admin')"
                  class="mb-1"
                >
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >Type of user</label>
                  <v-select
                    label="name"
                    :options="roles"
                    :get-option-key="option => option.id"
                    @search="onSearch({ name: $event }, 'roles/filter')"
                    @input="saveFilter($event, 'role')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >Version</label>
                  <v-select
                    label="name"
                    :options="versions"
                    :get-option-key="option => option.id"
                    @search="onSearch({ name: $event }, 'payrollsVersion/filter')"
                    @input="saveFilter($event, 'version')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >From</label>
                  <date-picker
                    v-model="filters['begin_date_from']"
                    format="D MMM Y"
                    value-type="format"
                    class="w-100"
                    @change="saveFilter($event, 'begin_date_from')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >To</label>
                  <date-picker
                    v-model="filters['begin_date_to']"
                    format="D MMM Y"
                    value-type="format"
                    class="w-100"
                    @change="saveFilter($event, 'begin_date_to')"
                  />
                </div>
              </template>
              <template #info-sheet-item>
                <div class="offcanvas-body offcanvas-body--view">

                  <h4 class="mb-2">
                    <strong class="text-success">{{ selectedItems.length }}</strong> selected registers
                  </h4>

                  <ul
                    id="basic-list-group"
                    class="list-group"
                  >
                    <li class="list-group-item draggable">
                      <div class="d-flex">
                        <div class="more-info">
                          <div class="form-check">
                            <input
                              id="tableAction1"
                              v-model="actions.downloadZip"
                              type="checkbox"
                              class="form-check-input"
                            >
                            <label
                              class="form-check-label"
                              for="tableAction1"
                            >Download zip</label>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="offcanvas-footer mt-auto">
                  <a
                    href="#"
                    title="apply actions"
                    class="btn btn-primary mb-1 d-grid w-100"
                    @click="applyActions"
                  >Apply actions</a>
                </div>
              </template>
            </CompleteTable>
          </div>
        </div>
      </div>
    </div>
    <AccessModal :file="selectedFile" />
  </div>
  <!-- END: Content-->
</template>

<script>
import { mapGetters } from 'vuex'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import { BDropdown, BDropdownItem } from 'bootstrap-vue'
import vSelect from 'vue-select'
import Vue from 'vue'
import AccessModal from '@/views/back/partials/modals/AccessModal.vue'
import CompleteTable from '../../../partials/components/CompleteTable.vue'

export default {
  components: {
    CompleteTable,
    BDropdown,
    BDropdownItem,
    vSelect,
    DatePicker,
    AccessModal,
  },
  data() {
    return {
      selectedFile: {},
      selectedItems: [],
      actions: {
        downloadZip: false,
      },
      sortedField: 'Name',
      defaultFields: [
        {
          name: 'Name',
          checked: true,
          order: 1,
          only_admin: true,
        },
        {
          name: 'User',
          checked: false,
          order: 2,
          only_admin: true,
        },
        {
          name: 'File name',
          checked: true,
          order: 3,
          only_admin: true,
        },
        {
          name: 'File',
          checked: false,
          order: 4,
        },
        {
          name: 'Date',
          checked: true,
          order: 5,
        },
        {
          name: 'Version',
          checked: true,
          order: 6,
        },
      ],
      sortMapping: {
        Name: 'user.name',
        User: 'user.roles_names',
        File: 'document_table',
        'File name': 'document_name',
        Date: 'created_at',
        Version: 'payroll_version.name',
      },
      title: 'Payrolls',
    }
  },
  computed: {
    ...mapGetters({
      fields: 'modals/tableSettingsFields',
      data: 'payrolls/items',
      dataTotal: 'payrolls/itemsTotal',
      filters: 'filters/filters',
      users: 'users/fakeUsers',
      loggedUser: 'auth/admin',
      lastUpdate: 'payrolls/lastUpdate',
      versions: 'payrollsVersion/items',
      roles: 'roles/allRoles',
      activeCode: 'auth/activeCode',
    }),
  },
  mounted() {
    this.reloadData()
    // console.log('mounteando', this.activeCode)
    // if (this.activeCode === 'OK') {
    //   console.log('creo que no entra')
    //   await this.$store.dispatch('modals/fetchUserFields', 'payrolls')
    //   await this.$store.dispatch('payrolls/filter', {})
    //   await this.$store.dispatch('payrolls/fetchLastUpdate')
    //   this.$store.dispatch('status/filterStatus', 'Payrolls')
    //   if (!this.fields || this.fields.length === undefined) { // If user doesnt have default fields, load the true default
    //     await this.$store.dispatch('modals/fetchFields', {
    //       fields: this.defaultFields,
    //       table: 'payrolls',
    //     })
    //   }
    // }
    // // setTimeout(() => {
    // //   feather.replace({
    // //     width: 14,
    // //     height: 14,
    // //   })
    // // }, 500)
  },
  methods: {
    applyActions() {
      if (this.selectedItems.length > 0) {
        // ADD ACCESS

        Vue.swal({
          title: 'Perform the following actions on the selected publications?',
          html: this.getActionsString(),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Yes, apply!',
        }).then(result => {
          if (result.isConfirmed) {
            const itemIds = this.selectedItems.map(el => el.id)
            this.$store.dispatch('payrolls/downloadZip', { itemIds }).then(() => {
              this.$store.dispatch('payrolls/filter', this.filters)
              this.$toastr.success('', 'Actions applied successfully!')
              this.selectedItems = []
              this.$store.dispatch('modals/toggleInfoItemSheet', false)
            })
          }
        })
      } else {
        this.$toastr.error('', 'No items selected!')
      }
    },
    getActionsString() {
      let str = ''

      if (this.actions.downloadZip) {
        str += '<p class="text-success">Download zips with selected items</p>'
      }

      return str
    },
    saveFilter(value, field) {
      this.filters[field] = value
      this.$store.dispatch('filters/saveFilters', {
        value,
        field,
      })
    },
    async onSearch(search, store) {
      search.active = true
      if (search.name !== '') {
        await this.$store.dispatch(store, search)
      }
    },
    async showDeleteModal(id) {
      await this.$store.dispatch('modals/saveDeleteData', { name: 'payrolls', url: `${Vue.prototype.$groupUrl}/payrolls/${id}` })
      this.$store.dispatch('modals/showDeleteModal', true)
    },
    saveSelectedItems(items) {
      this.selectedItems = items
    },
    async downloadZip() {
      await this.$store.dispatch('payrolls/downloadZip', { selectedItems: this.selectedItems })
    },
    async downloadFile(file) {
      await this.$store.dispatch('payrolls/downloadFile', { file })
    },
    async reloadData() {
      await this.$store.dispatch('auth/fetchActiveCode', this.loggedUser.id)
      if (this.activeCode !== 'OK') {
        await this.$store.dispatch('modals/saveOtpData', { type: 'reload' })
        this.$store.dispatch('modals/showOtpModal', true)
        return
      }
      await this.$store.dispatch('modals/fetchUserFields', 'payrolls')
      await this.$store.dispatch('payrolls/filter', this.filters)
      await this.$store.dispatch('payrolls/fetchLastUpdate')
      this.$store.dispatch('status/filterStatus', 'Payrolls')
      if (!this.fields || this.fields.length === undefined) { // If user doesnt have default fields, load the true default
        await this.$store.dispatch('modals/fetchFields', {
          fields: this.defaultFields,
          table: 'payrolls',
        })
      }
      setTimeout(() => {
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 500)
    },
  },
}
</script>
